<template>
  <ul class="topic">
    <li v-for="(item, index) in hots" :key="index" @click="detail(item)">
      <van-image lazy-load round :src="item.image || item.avatar">
        <template v-slot:default>
          <span class="vip-icon" v-if="item.is_vip == 1 && item.is_free == 1">VIP</span>
          <span v-if="item.lessonCount > 1" class="lessonCount"
            >{{ item.lessonCount }} {{ checkType(item.type) }}</span
          >
        </template>
      </van-image>
      <p class="cate_name">{{ item.title }}</p>
      <p class="total_num" v-if="item.orderCount">
        {{ item.orderCount }} ادام ساتىپ الدى
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    hots: {
      type: Array
    },
    clickType: {
      type: String,
      default: 'course'
    }
  },
  methods: {
    detail ({ id, type, lessonCount }) {
      if (lessonCount === 0) {
        this.$toast('قازىرشە مازمۇن قوسىلمادى')
        return
      }
      let path = ''
      if (this.clickType === 'course') {
        path = { name: 'detail', params: { parent_id: id, type } }
      } else {
        path = {
          path: '/topic',
          query: {
            id
          }
        }
      }
      this.$router.push(path)
    },
    checkType (type) {
      let name = ''
      switch (type) {
        case 'article':
          name = 'ماقالا'
          break
        case 'audio':
          name = 'اۋديو'
          break
        case 'video':
          name = 'ۆيديو'
          break
      }
      return name
    }
  }
}
</script>

<style lang="less" scoped>
// 专题
.topic {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  background-color: #fff;
  li {
    display: block;
    width: 33.333%;
    margin: 0;
    text-align: center;
    /deep/ .van-image {
      width: 94%;
      height: 320px;
      border-radius: 6px !important;
      object-fit: cover;
      margin-bottom: 14px;
      vertical-align: top;
    }
    .cate_name {
      font-size: 28px;
      text-align: center;
      padding: 0 20px;
      color: #3a3a3a;
      margin-bottom: 10px;
    }
    .total_num {
      direction: rtl;
      color: #ee0a24;
      height: 38px;
      font-size: 22px;
      padding: 0 5px;
    }
  }
}
</style>
